import { signIn, useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import EasyDuctLogo from "../../public/images/easy-duct-logo.png";

import { faArrowRight, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TryItNow from "../../components/modal/TryItNow";
import { pageItems } from "../../configs/page-items";
import UserMenu from "./UserMenu";

const Header = () => {
  const router = useRouter();
  const { status, data: session } = useSession();
  const isLoggedIn = status === "authenticated" || status === "loading";
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <div className="boxed-container">
        <div className="flex items-center justify-between mt-10 mb-5">
          <Link href={"/"} passHref>
            <div>
              <Image
                src={EasyDuctLogo.src}
                width={251}
                height={46}
                alt="EasyDuct Logo"
                className="cursor-pointer"
              />
            </div>
          </Link>
          <div className="flex items-center gap-10">
            {pageItems.map((page) => (
              <div key={page.label} className="flex justify-center min-w-min">
                <Link
                  scroll={false}
                  passHref
                  href={page.path}
                  className={`${
                    page.status === "disabled" ? "pointer-events-none" : ""
                  }`}
                >
                  <span
                    className={`cursor-pointer ${
                      router.pathname === page.path
                        ? "font-extrabold"
                        : "font-medium"
                    }`}
                  >
                    {page.label}
                  </span>
                </Link>
              </div>
            ))}
            <div className="flex items-center gap-5">
              {!isLoggedIn && (
                <div
                  className="flex items-center gap-2 btn btn-dark-gray"
                  onClick={onOpenModal}
                >
                  <div>Try now</div>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              )}

              <div>
                {isLoggedIn && session ? (
                  <UserMenu session={session} />
                ) : (
                  <button
                    className="btn btn-dark-gray"
                    onClick={() => signIn()}
                  >
                    Login
                    <FontAwesomeIcon className="ml-2" icon={faLock} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TryItNow isOpen={open} onCloseFn={onCloseModal} />
    </>
  );
};

export default Header;
