import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    setIsLoading(true);
    const mailData = {
      formValues: getValues(),
    };

    const res = await fetch("/api/home-contact-us-mailer", {
      body: JSON.stringify(mailData),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    const { error } = await res.json();
    if (error) {
      console.error(error);
      return;
    }

    //ADD USER INFO TO DB
    const user = {
      name: `${getValues().fname} ${getValues().lname}`,
      email: getValues().email,
      dateAdded: Date.now(),
    };

    const adduser = await fetch("/api/user", {
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    //SUCCESS
    setSubmitSuccess(true);
    reset();
    setIsLoading(false);
  };

  return (
    <div id="home-contact-form">
      {submitSuccess && (
        <div>
          <div className="font-bold mb-5">
            Thank you for your inquiry. {"We'll"} be in touch shortly.
          </div>
          <div
            onClick={() => setSubmitSuccess(false)}
            className="underline cursor-pointer"
          >
            Click to send another message.
          </div>
        </div>
      )}
      {!submitSuccess && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5 justify-center w-full md:w-[70%] m-auto">
            <div className="flex flex-col md:flex-row gap-5 w-full">
              <div className="w-full">
                <input
                  placeholder="First Name"
                  type="text"
                  {...register("fname", {
                    required: "This field is required",
                  })}
                />
                {errors.fname && (
                  <div className="text-red-500 text-left mt-1">
                    {errors.fname.message}
                  </div>
                )}
              </div>
              <div className="w-full">
                <input
                  placeholder="Last Name"
                  type="text"
                  {...register("lname", {
                    required: "This field is required",
                  })}
                />
                {errors.lname && (
                  <div className="text-red-500 text-left mt-1">
                    {errors.lname.message}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-5 w-full">
              <div className="w-full md:w-[60%]">
                <input
                  placeholder="Email"
                  type="text"
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                />
                {errors.email && (
                  <div className="text-red-500 text-left mt-1">
                    {errors.email.message}
                  </div>
                )}
              </div>
              <div className="w-full md:w-[40%]">
                <input
                  placeholder="Phone Number"
                  type="number"
                  {...register("phone", {
                    required: "This field is required",
                  })}
                />
                {errors.phone && (
                  <div className="text-red-500 text-left mt-1">
                    {errors.phone.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <textarea
                placeholder="Message"
                rows={5}
                {...register("message", {
                  required: "This field is required",
                })}
              />
              {errors.message && (
                <div className="text-red-500 text-left">
                  {errors.message.message}
                </div>
              )}
            </div>
            <button
              className={`submit-btn ${
                isLoading ? "pointer-events-none opacity-70" : ""
              }`}
              type="submit"
            >
              {isLoading ? (
                <div className="flex gap-2 items-center">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    size={"lg"}
                    className="animate-spin"
                  />
                  Sending...
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  Try it Now
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
