import ContactForm from "./ContactForm";

const ContactUs = () => {
  return (
    <div id="contact-us" className="bg-full-bleed-gray pt-36 pb-10">
      <div className="flex gap-2 items-center justify-center font-bold text-black">
        <div>Easier</div>
        <div>|</div>
        <div>Faster</div>
        <div>|</div>
        <div>Error Free</div>
      </div>

      <div className="text-center">
        <div className="text-5xl font-extrabold mt-16 mb-5">
          Still not convinced?
        </div>
        <div className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#8DF924] to-[#00B4FF]">
          Please drop us a line.
        </div>
        <div className="mt-12">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
