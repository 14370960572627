import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import { pageItems } from "../../configs/page-items";
import EmailIcon from "../../public/images/home/email-icon.svg";
import FacebookIcon from "../../public/images/home/facebook.svg";
import LinkedInIcon from "../../public/images/home/linkedin.svg";
import PhoneIcon from "../../public/images/home/phone-icon.svg";
import TwitterIcon from "../../public/images/home/twitter.svg";
import EasyDuctLogo from "../../public/images/logo/easy-duct-logo.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="pb-12 bg-full-bleed-gray pt-36">
      <div className="flex gap-10">
        <div className="w-[45%]">
          <Link href={"/"} passHref>
            <div>
              <Image
                src={EasyDuctLogo.src}
                width={251}
                height={46}
                alt="EasyDuct Logo"
              />
            </div>
          </Link>
          <div className="my-12 font-semibold">
            EasyDuct App is designed to give mechanical contractors a tool that
            is easier than paper, faster than going back and forth with
            manufacturers, and error-free by eliminating wrong measurements.
          </div>
          <div className="flex gap-5 mb-5">
            <Image src={PhoneIcon.src} width={20} height={20} alt="" />
            <Link href="tel:425-270-8466" passHref>
              <div className="font-semibold">(425) 270-8466</div>
            </Link>
          </div>
          <div className="flex gap-5">
            <Image src={EmailIcon.src} width={20} height={20} alt="" />
            <Link href="mailto:info@easyduct.app" passHref>
              <div className="font-semibold">info@easyduct.app</div>
            </Link>
          </div>
        </div>
        <div className="w-[25%] pl-10">
          <div className="flex flex-col mt-3 gap-14">
            {pageItems.map((page) => (
              <div key={page.label} className="flex items-center gap-8">
                <FontAwesomeIcon icon={faArrowRightLong} />
                <Link href={page.path} passHref className="pointer-events-none">
                  <div className="font-semibold cursor-pointer">
                    {page.label}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="w-[35%]">
          <div className="text-4xl font-black">Follow Us</div>
          <div className="py-10 font-semibold">
            {"We'd"} love to meet and hear from you. Tell us what you think!
          </div>
          <div className="flex gap-10">
            <Link href="/">
              <div>
                <Image src={FacebookIcon.src} width={40} height={40} alt="" />
              </div>
            </Link>
            <Link href="/">
              <div>
                <Image src={TwitterIcon.src} width={40} height={40} alt="" />
              </div>
            </Link>
            <Link href="/">
              <div>
                <Image src={LinkedInIcon.src} width={40} height={40} alt="" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-28">
        <span className="font-semibold">
          © {currentYear} <span className="font-bold">EasyDuct App</span> | All
          Rights Reserved | Powered by{" "}
          <Link href="https://coloredboxes.net/" passHref target={"_blank"}>
            <span className="font-bold">Coloredboxes</span>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Footer;
