import {
  faArrowRightLong,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { nanoid } from "nanoid";
import Image from "next/image";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-responsive-modal";
import EasyDuctLogo from "../../public/images/logo/logo-big.png";
import useInstance from "../../state/useInstance";

const TryItNow = ({ isOpen, onCloseFn, registrationPurpose }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { instance } = useInstance();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const onModalClose = () => {
    reset();
    setSubmitSuccess(false);
    onCloseFn();
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setMessage("");

    const result = await axios.post("api/user-exists", {
      email: data.TinEmail,
    });

    // Check if user already added
    if (result.data.exist) {
      setSubmitSuccess(false);
      setIsLoading(false);
      setMessage(<div className="error-msg">Email already exist</div>);
      return;
    }

    // Add user to datasbase
    const newUser = {
      name: getValues().TinName,
      email: getValues().TinEmail,
      password: nanoid(8), // generate random password
      date_added: Date.now(),
      instance: instance._id,
    };
    const adduser = await axios.post("/api/add-user", newUser);

    // After adding user, send an email
    if (adduser) {
      const mailData = {
        formValues: getValues(),
        purpose: registrationPurpose,
      };
      const res = await fetch("/api/try-it-now-mailer", {
        body: JSON.stringify(mailData),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      const { error } = await res.json();
      if (error) {
        console.error(error);
        return;
      }
      setSubmitSuccess(true);
      reset();
    }

    setIsLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      classNames={{
        modal: "tryItNow",
      }}
      styles={{
        modal: {
          marginTop: "15vh",
          width: registrationPurpose ? "580px" : "480px",
        },
      }}
    >
      <div className="close-icon">
        <FontAwesomeIcon
          icon={faCircleXmark}
          size={"xl"}
          className="cursor-pointer"
          onClick={onModalClose}
        />
      </div>

      <div className="content-wrapper">
        <div className="logo">
          <Image
            src={EasyDuctLogo.src}
            width={360}
            height={60}
            alt="EasyDuct Logo"
          />
        </div>
        {submitSuccess && (
          <div className="flex flex-col gap-5 mt-10 font-semibold">
            <div>Thank you for your interest!</div>
            <div>
              We are sending you an email so you can try out EasyDuct for
              yourself, right now!
            </div>
          </div>
        )}
        {!submitSuccess && (
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-field-wrapper">
                <div className="mb-3">
                  <input
                    placeholder="Enter Your Name"
                    type="text"
                    className={`field-input ${
                      errors.TinName ? "invalid-input" : ""
                    }`}
                    {...register("TinName", {
                      required: "This field is required",
                    })}
                  />
                  {errors.TinName && (
                    <div className="error-msg">{errors.TinName.message}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    placeholder="Enter Your Best Email"
                    type="email"
                    className={`field-input ${
                      errors.TinEmail ? "invalid-input" : ""
                    }`}
                    {...register("TinEmail", {
                      required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please enter a valid email format",
                      },
                    })}
                  />
                  {errors.TinEmail && (
                    <div className="error-msg">{errors.TinEmail.message}</div>
                  )}
                  {message && message}
                </div>
                <button
                  className={`submit-btn ${
                    isLoading ? "pointer-events-none opacity-70" : ""
                  }`}
                  type="submit"
                >
                  {isLoading ? (
                    <div className="flex items-center gap-2 ">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size={"lg"}
                        className="spinner"
                      />
                      Sending...
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      {registrationPurpose
                        ? "I’m Interested In Investing Details"
                        : "I Want To Try EasyDuct"}
                      <FontAwesomeIcon icon={faArrowRightLong} size={"lg"} />
                    </div>
                  )}
                </button>
              </div>
            </form>

            {registrationPurpose ? (
              <div className="text-msg">
                <span>
                  Thank you for your interest in investing in EasyDuct!
                </span>
                <span>
                  We are currently seeking investors who share our vision of
                  digitally transforming the manufacturing industry. If{" "}
                  {"you're"}
                  interested in learning more about our investment
                  opportunities, click the button above and {"we'll"} send you
                  an email shortly with a Calendly link to schedule a time to
                  speak with our team and hear our investor story directly from
                  us.
                </span>
                <span>
                  Thank you for considering EasyDuct for your investment. We
                  look forward to being with you soon!
                </span>

                <span>
                  Cheers,
                  <br /> -Josh
                </span>
              </div>
            ) : (
              <div className="text-msg">
                <span>
                  Thank you for your interest of our app. We are currently on
                  Beta and we are letting just a few people to try our App.
                </span>
                <span>
                  To get a free trial please enter your name and email and click
                  the I Want To Try EasyDuct.
                </span>

                <span>Thank you! See you soon.</span>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TryItNow;
