import { Menu } from "@headlessui/react";
import { signOut } from "next-auth/react";

import {
  faCircleUser,
  faRightFromBracket,
  faScissors,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";

const UserMenu = ({ session }) => {
  const router = useRouter();
  return (
    <div className="relative">
      <Menu>
        <Menu.Button className="flex items-center px-3 py-2 text-white bg-[#282D35] rounded-md">
          Hi, {session?.user.name}
        </Menu.Button>

        <Menu.Items className="mt-[2px] right-0 min-w-[160px] absolute text-white rounded-lg shadow-md bg-[#282D35] py-2">
          <Menu.Item className="px-5 py-2">
            <div className="flex items-center gap-4">
              <FontAwesomeIcon icon={faScissors} className="text-lg" />
              <div
                className="cursor-pointer"
                onClick={() => router.push("/cut-sheet")}
              >
                App
              </div>
            </div>
          </Menu.Item>
          <Menu.Item className="px-5 py-2">
            <div className="flex items-center gap-4">
              <FontAwesomeIcon icon={faCircleUser} className="text-lg" />
              <div
                className="cursor-pointer"
                onClick={() => router.push("/account")}
              >
                Account
              </div>
            </div>
          </Menu.Item>
          <Menu.Item className="px-5 py-2">
            <div className="flex items-center gap-4">
              <FontAwesomeIcon icon={faRightFromBracket} className="text-lg" />
              <div className="cursor-pointer" onClick={signOut}>
                Log out
              </div>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default UserMenu;
